.app {
  height: 100vh; // will need to fix this after adding navbar
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1000px;
  margin: auto;
  padding: 0 20px;
}
